@import "~theme-css/bootstrap/variables.less";
@import "~theme-css/bootstrap/mixins.less";
@import '~theme-css/bootstrap/progress-bars.less';

// main app loader
#loading {
	.progress-bar {
		margin-top: -50px;
		position: absolute;
		top: 60%;
		-moz-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-moz-transition: opacity 0.4s;
		-webkit-transition: opacity 0.4s;
		transition: opacity 0.4s;
		&.done {
			opacity: 0;
		}
		svg {
			-moz-animation: rotate 2s infinite linear;
			-webkit-animation: rotate 2s infinite linear;
			animation: rotate 2s infinite linear;
		}
		span {
			color: #fff;
			display: inline-block;
			position: absolute;
			text-align: center;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			line-height: 100px;
			width: 100px;
		}
		.number {
			color: #343d5b;
			font-size: 36px;
		}
		.percent {
			display: none;
		}
	}
}

.loader {
	zoom: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.container {
		display: flex;
		flex: 0 0 150px;
		flex-direction: column;
		.ball {
			display: flex;
			flex: 0 0 auto;
			align-items: center;
			justify-content: center;
			margin: 2.5px auto;
			width: 100%;
			&::after {
				content: '';
				border-radius: 50px;
				flex: 0 0 auto;
				width: 8px;
				height: 8px;
			}
		}
	}
}

// main app loader
#loading {
	align-items: center;
	justify-content: center;
	background-color: var(--mdc-theme-background);
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: 1038;
	> .container {
		flex: 1 1 auto;
		min-height: 0;
		overflow: hidden;
	}
	.loader {
		display: flex;
		flex: 1 1 auto;
		min-height: 0;
		overflow: hidden;
		.container {
			flex: 0 0 160px;
			min-width: 0;
			.ball {
				margin: 5px auto;
				&::after {
					background: var(--mdc-theme-primary);
					width: 10px;
					height: 10px;
				}
			}
		}
	}
	&.done {
		.loader {
			.container {
				.ball {
					opacity: 0;
					&:nth-child(1), &:nth-child(7) {
						-moz-transition: opacity 1s ease-out;
						-webkit-transition: opacity 1s ease-out;
						transition: opacity 1s ease-out;
					}
					&:nth-child(2), &:nth-child(5) {
						-moz-transition: opacity 1.1s ease-out;
						-webkit-transition: opacity 1.1s ease-out;
						transition: opacity 1.1s ease-out;
					}
					&:nth-child(3), &:nth-child(6) {
						-moz-transition: opacity 1.05s ease-out;
						-webkit-transition: opacity 1.05s ease-out;
						transition: opacity 1.05s ease-out;
					}
					&:nth-child(4) {
						-moz-transition: opacity 1.15s ease-out;
						-webkit-transition: opacity 1.15s ease-out;
						transition: opacity 1.15s ease-out;
					}
				}
			}
		}
	}
}

// general purpose loader rules:
// show .spinners inside of .loading things, hide them otherwise.
.loader {
	display: none;
}
// TODO: make loading stuff a mixin
#loading .loader,
[data-nx-loading='true'] .loader {
	display: flex;
	.container {
		.ball {
			&::after {
				-moz-transition: background 1s ease-out;
				-webkit-transition: background 1s ease-out;
				transition: background 1s ease-out;
			}
			&:nth-child(1), &:nth-child(7) {
				&::after {
					-moz-animation: loading_ball_right 1s infinite ease-in-out;
					-webkit-animation: loading_ball_right 1s infinite ease-in-out;
					animation: loading_ball_right 1s infinite ease-in-out;
				}
			}
			&:nth-child(2) {
				&::after {
					-moz-animation: loading_ball_left 1.1s infinite ease-in-out;
					-webkit-animation: loading_ball_left 1.1s infinite ease-in-out;
					animation: loading_ball_left 1.1s infinite ease-in-out;
				}
			}
			&:nth-child(3) {
				&::after {
					-moz-animation: loading_ball_right 1.05s infinite ease-in-out;
					-webkit-animation: loading_ball_right 1.05s infinite ease-in-out;
					animation: loading_ball_right 1.05s infinite ease-in-out;
				}
			}
			&:nth-child(4) {
				&::after {
					-moz-animation: loading_ball_left 1.15s infinite ease-in-out;
					-webkit-animation: loading_ball_left 1.15s infinite ease-in-out;
					animation: loading_ball_left 1.15s infinite ease-in-out;
				}
			}
			&:nth-child(5) {
				&::after {
					-moz-animation: loading_ball_right 1.1s infinite ease-in-out;
					-webkit-animation: loading_ball_right 1.1s infinite ease-in-out;
					animation: loading_ball_right 1.1s infinite ease-in-out;
				}
			}
			&:nth-child(6) {
				&::after {
					-moz-animation: loading_ball_left 1.05s infinite ease-in-out;
					-webkit-animation: loading_ball_left 1.05s infinite ease-in-out;
					animation: loading_ball_left 1.05s infinite ease-in-out;
				}
			}
		}
	}
}

@-webkit-keyframes loading_ball_left {
	0% {
		-webkit-transform: translateX(15px);
	}
	50% {
		-webkit-transform: translateX(-15px);
	}
	100% {
		-webkit-transform: translateX(15px);
	}
}
@-moz-keyframes loading_ball_left {
	0% {
		-moz-transform: translateX(15px);
	}
	50% {
		-moz-transform: translateX(-15px);
	}
	100% {
		-moz-transform: translateX(15px);
	}
}
@keyframes loading_ball_left {
	0% {
		transform: translateX(15px);
	}
	50% {
		transform: translateX(-15px);
	}
	100% {
		transform: translateX(15px);
	}
}

@-moz-keyframes loading_ball_right {
	0% {
		-moz-transform: translateX(-15px);
	}
	50% {
		-moz-transform: translateX(15px);
	}
	100% {
		-moz-transform: translateX(-15px);
	}
}
@-webkit-keyframes loading_ball_right {
	0% {
		-webkit-transform: translateX(-15px);
	}
	50% {
		-webkit-transform: translateX(15px);
	}
	100% {
		-webkit-transform: translateX(-15px);
	}
}
@keyframes loading_ball_right {
	0% {
		transform: translateX(-15px);
	}
	50% {
		transform: translateX(15px);
	}
	100% {
		transform: translateX(-15px);
	}
}

#nx-app-progress {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 4px;
	z-index: 40;
	opacity: 0;
	z-index: 1039;
	.progress-bar {
		background-color: var(--mdc-theme-primary);
	}
	&.active {
		opacity: 1;
	}
}
#nx-app-message {
	display: none;
}
#nx-progress-wrapper.progress {
	height: 4px;
	background-color: var(--mdc-theme-background);
	// opacity: 0.8;
	border: 0;
	.progress-bar {
		height: 4px;
	}
}