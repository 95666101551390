:root {
	color-scheme: light;
	
	// --mdc-theme-login-background-image: url(~theme-img/transparent_1x1.png);
	// --mdc-theme-login-background-position: center center;
	// --mdc-theme-login-background-repeat: no-repeat;

	--mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
	--mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
	--mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-primary-on-dark: #ffffff;
	--mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
	--mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
	--mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
	--mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);

	--mdc-theme-secondary: #aaa;
	--mdc-theme-primary: #ccc;
	--mdc-portal-primary: #ccc;

	--mdc-theme-error: #ff2525;
	--mdc-theme-on-error: #fff;
	--mdc-portal-on-primary: #fff;
	--mdc-theme-on-secondary: #fff;

	// mdc-elevation--z2
	--mdc-theme-custom-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	--mdc-theme-custom-surface-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.theme-light {
	color-scheme: light;
	--mdc-theme-background: #fafafa;
	--mdc-theme-on-background: #111;
	--mdc-theme-on-surface: #111;
	--mdc-theme-surface: #fafafa;
	--mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
	--mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
	--mdc-theme-custom-background-accent: rgba(0, 0, 0, 0.02);
	--mdc-theme-custom-background-light: rgba(0, 0, 0, 0.04);
	--mdc-theme-custom-background-medium: rgba(0, 0, 0, 0.2);
	--mdc-theme-custom-backdrop: rgba(250, 250, 250, 0.85);
	--mdc-theme-custom-header: rgba(250, 250, 250, 0.95);
	--mdc-theme-custom-border: rgba(0, 0, 0, 0.08);
}

.theme-dark {
	color-scheme: dark;
	--mdc-theme-background: #242424;
	--mdc-theme-on-background: #eee;
	--mdc-theme-on-surface: #eee;
	--mdc-theme-surface: #242424;
	--mdc-theme-text-hint-on-background: rgba(255, 255, 255, 0.68);
	--mdc-theme-text-icon-on-background: rgba(255, 255, 255, 0.87);
	--mdc-theme-text-disabled-on-background: rgba(255, 255, 255, 0.38);
	--mdc-theme-text-primary-on-background: rgba(255, 255, 255, 0.87);
	--mdc-theme-text-secondary-on-background: rgba(255, 255, 255, 0.54);
	--mdc-theme-custom-backdrop: rgba(36, 36, 36, 0.75);
	--mdc-theme-custom-header: rgba(36, 36, 36, 0.95);
	--mdc-theme-custom-background-accent: rgba(255, 255, 255, 0.02);
	--mdc-theme-custom-background-light: rgba(255, 255, 255, 0.04);
	--mdc-theme-custom-background-medium: rgba(255, 255, 255, 0.1);
	--mdc-theme-custom-border: rgba(255, 255, 255, 0.16);
}

// base
// --------------------------------------------------------------------------------------------------*/
html {
	height: 100%;
}
body {
	// font: normal 16px/18px sans-serif, arial;
	// color: var(--mdc-theme-on-surface);
	// background: var(--mdc-theme-background);
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

* {
    box-sizing: border-box;
}

// Remove outlines
*,
button,
input,
a {
	&:focus,
	&:active,
	&:active:focus,
	&.active:focus {
		outline: none;
		outline-color: transparent;
		outline-offset: 0;
		outline-style: none;
		text-decoration: none;
	}
}

//
// NOSCRIPT
// =========
// This is done this way as script blockers also stop NOSCRIPT
#javascript-required {
	background: #485170;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10000;
	> div {
		color: #fff;
		font-size: 20px;
		font-weight: 900;
		height: 100%;
		padding: 1.5em;
		text-align: center;
		width: 100%;
		i {
			display: block;
			font-size: 8.5em;
			margin-bottom: 1.5em;
		}
		.help-text {
			display: block;
			font-size: 1em;
			font-weight: normal;
			width: inherit;
		}
		p + .help-text {
			margin-top: 1.5em;
		}
	}
}
